function handleClose(close: any, router: any, href: any) {
    close()
    router.push(href)
}

export default function createCloseHandler(close: any, router: any, href: any) {
    return function () {
        handleClose(close, router, href)
    }
}
