import {cva} from 'class-variance-authority'

export const buttonClasses = cva(
    'inline-flex whitespace-nowrap items-center transition-colors ease-in',
    {
        variants: {
            variant: {
                primary: '',
                secondary:
                    'text-slate-600/85 bg-slate-300/10 border border-slate-950/10 backdrop-filter-[12px] hover:bg-slate-300/25',
                navigation: 'font-medium text-gray-900',
                vibrant:
                    'text-white font-medium bg-gradient-to-r from-orange-400 via-rose-500 to-pink-500 hover:from-orange-400 hover:to-orange-500',
                contact:
                    'text-gray-800/85 font-medium bg-slate-300/15 border border-gray-600/80 hover:bg-slate-200/75',
                high: 'bg-gradient-to-b from-sky-500 to-blue-600 text-white border-b border-t border-t-sky-200 border-b-blue-900 hover:from-blue-600 hover:to-blue-600',
                section: 'text-slate-800/85 font-medium',
            },
            size: {
                icon: 'p-2',
                small: 'text-sm h-7 px-2',
                medium: 'text-base h-9 px-3',
                large: 'text-lg h-11 px-4',
                xlarge: 'text-xl h-12 px-4',
            },
            shape: {
                round: 'rounded-full',
                rounded: 'rounded-md',
            },
        },
    },
)
