import {
    ArrowPathIcon,
    CursorArrowRaysIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/outline'

import {navLinks} from './NavLinks'

export default function getNavProducts() {
    return {
        products: [
            {
                name: 'appointments',
                href: navLinks.appointments,
                icon: RectangleGroupIcon,
            },
            {
                name: 'engagement',
                href: navLinks.medical,
                icon: CursorArrowRaysIcon,
            },
            {
                name: 'automations',
                href: navLinks.finance,
                icon: ArrowPathIcon,
            },
            {
                name: 'integrations',
                href: navLinks.integrations,
                icon: SquaresPlusIcon,
            },
            // {
            //     name: 'security',
            //     href: navLinks.security,
            //     icon: FingerPrintIcon,
            // },
        ],
    } as const
}
