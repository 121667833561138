import {Pathnames} from 'next-intl/dist/types/src/routing/types'
import {createLocalizedPathnamesNavigation} from 'next-intl/navigation'

export const locales = ['en', 'nl'] as const
export type SupportedLocales = (typeof locales)[number]
export const localePrefix = 'always'
export const pathnames = {
    '/': '/',
    '/#appointments': '/#appointments',
    '/#medical': '/#medical',
    '/#finance': '/#finance',
    '/product': {
        en: '/product',
        nl: '/product',
    },
    '/product/analytics': {
        en: '/product',
        nl: '/product',
    },
    '/integrations': {
        en: '/integrations',
        nl: '/integraties',
    },
    '/integrations/[slug]': {
        en: '/integrations/[slug]',
        nl: '/integraties/[slug]',
    },
    '/security': {
        en: '/security',
        nl: '/beveiliging',
    },
    '/pricing': {
        en: '/pricing',
        nl: '/prijzen',
    },
    '/customers': {
        en: '/customers',
        nl: '/klanten',
    },
    '/customers/[slug]': {
        en: '/customers/[slug]',
        nl: '/klanten/[slug]',
    },
    '/changelog': {
        en: '/changelog',
        nl: '/updates',
    },
    '/changelog/[slug]': {
        en: '/changelog/[slug]',
        nl: '/updates/[slug]',
    },
    '/about': {
        en: '/about',
        nl: '/over-flux',
    },
    '/blog': {
        en: '/blog',
        nl: '/blog',
    },
    '/blog/[slug]': {
        en: '/blog/[slug]',
        nl: '/blog/[slug]',
    },
    '/contact': {
        en: '/contact',
        nl: '/contact',
    },
    '/[type]/[slug]': {
        en: '/contact',
        nl: '/contact',
    },
} as const satisfies Pathnames<typeof locales>

export type InternalPath = keyof typeof pathnames

export const {Link, redirect, usePathname, useRouter, getPathname} =
    createLocalizedPathnamesNavigation({locales, localePrefix, pathnames})
