'use client'
import {usePathname, useRouter} from 'next/navigation'
import {useLocale} from 'next-intl'
import {useCallback, useEffect, useState} from 'react'

import {getTranslations} from '@/i18n/getTranslations'
import {getLocalizedSlugs} from '@/sanity/queries/getAllLocalizedSlugs'

type PageType = 'changelog' | 'customer' | 'blog' | 'integration'

export default function LocaleSwitcher() {
    const locale = useLocale() // Huidige locale
    const [translations, setTranslations] = useState<any>(null) // Vertalingen in state opslaan
    const router = useRouter()
    const Pathname = usePathname() || '' // Huidige path zonder query parameters

    // Functie om het pageType af te leiden
    const determinePageType = useCallback((pathname: string): PageType | null => {
        if (pathname.includes('/changelog') || pathname.includes('/updates')) {
            return 'changelog'
        }
        if (pathname.includes('/customer') || pathname.includes('/klanten')) {
            return 'customer'
        }
        if (pathname.includes('/blog')) {
            return 'blog'
        }
        if (pathname.includes('/integration') || pathname.includes('/integraties')) {
            return 'integration'
        }
        return null // Als geen type gevonden is
    }, [])

    const getTranslatedSlug = useCallback(
        async (newLocale: string) => {
            const segments = Pathname.split('/') // Split het pad in segmenten
            const currentSlug = segments[segments.length - 1] // Haal de slug op (laatste segment van de URL)
            const pageType = determinePageType(Pathname)
            // Als er geen slug is (bijv. de URL eindigt met een slash of leeg is), return gewoon null
            if (!currentSlug || currentSlug === '') {
                return null
            }
            if (!pageType) {
                console.error('Unknown page type')
                return null
            }
            try {
                // Vraag de vertaalde slug op voor het huidige type pagina
                const localizedSlug = await getLocalizedSlugs(pageType, currentSlug)

                // Zoek de vertaalde slug voor de nieuwe locale
                const newSlug =
                    localizedSlug.find((slugObj) => slugObj.language === newLocale)?.slug ||
                    currentSlug

                return newSlug
            } catch (error) {
                console.error('Error fetching localized slugs:', error)
                // Vervang alleen de locale als er een fout optreedt
                return currentSlug
            }
        },
        [Pathname, determinePageType],
    )

    const onLocaleChange = useCallback(
        async (e: React.ChangeEvent<HTMLSelectElement>) => {
            const newLocale = e.target.value
            const segments = Pathname.split('/')
            const currentSlug = segments[segments.length - 1]
            const newSlug = await getTranslatedSlug(newLocale)

            // Als er een vertaalde slug is, vervang dan zowel de locale als de slug
            if (newSlug && newSlug !== currentSlug) {
                const newPathname = Pathname.replace(`/${locale}`, `/${newLocale}`) // Locale vervangen
                    .replace(currentSlug, newSlug) // Slug vervangen

                router.replace(newPathname)
            } else {
                // Als er geen vertaalde slug is, vervang alleen de locale
                const newPathname = Pathname.replace(`/${locale}`, `/${newLocale}`)
                router.replace(newPathname)
            }
        },
        [locale, Pathname, router, getTranslatedSlug],
    )

    useEffect(() => {
        const loadTranslations = async () => {
            const translator = await getTranslations({locale, namespace: 'LocaleSwitcher'})
            setTranslations(() => translator)
        }
        loadTranslations()
    }, [locale])

    if (!translations) {
        return <div>Loading...</div>
    }

    return (
        <select defaultValue={locale} onChange={onLocaleChange}>
            {['nl', 'en'].map((lang) => (
                <option key={lang} value={lang}>
                    {translations('locale', {locale: lang})}
                </option>
            ))}
        </select>
    )
}
