'use client'
import {useTranslations} from 'next-intl'

import InternalButton from '../ui/internalbutton'
import NavCompanyMenu from './NavCompanyMenu'
import {navLinks} from './NavLinks'
import NavProductMenu from './NavProductMenu'

export default function RenderNonMobileNav() {
    const t = useTranslations('Nav')

    return (
        <ul className="flex flex-row space-x-1 justify-center items-center">
            <li>
                <NavProductMenu />
            </li>
            {/* <li>
                <InternalButton
                    href={navLinks.pricing}
                    variant="navigation"
                    size="medium"
                    shape="rounded"
                >
                    {t('topLevel.pricing')}
                </InternalButton>
            </li> */}
            <li>
                <InternalButton
                    href={navLinks.customers}
                    variant="navigation"
                    size="medium"
                    shape="rounded"
                >
                    {t('topLevel.customers')}
                </InternalButton>
            </li>
            <li>
                <InternalButton
                    href={navLinks.changelog}
                    variant="navigation"
                    size="medium"
                    shape="rounded"
                >
                    {t('topLevel.changelog')}
                </InternalButton>
            </li>
            <li>
                <NavCompanyMenu />
            </li>
            <li>
                <InternalButton
                    href={navLinks.contact}
                    variant="navigation"
                    size="medium"
                    shape="rounded"
                >
                    {t('topLevel.contact')}
                </InternalButton>
            </li>
        </ul>
    )
}
