import {cva} from 'class-variance-authority'

export const layoutClasses = cva(
    'mx-auto max-w-7xl w-full pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
    {
        variants: {
            variant: {
                primary: 'flex flex-col',
                secondary: 'flex flex-col py-6 mt-28 md:mt-20 lg:mt-40 gap-20 mb-40',
                content: 'flex flex-col py-6 mt-24 mb-40',
            },
        },
    },
)
