import {Popover, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {Link, useRouter} from 'navigation'
import {useTranslations} from 'next-intl'
import React, {Fragment, useCallback} from 'react'

import createCloseHandler from './NavClose'
import getCompany from './NavCompany'

export default function NavCompanyMenu() {
    const t = useTranslations('Nav')
    const router = useRouter()
    const {company} = getCompany()

    const handleKeyDown = useCallback(
        (
            item: (typeof company)[number],
            index: number,
            close: (
                focusableElement?: HTMLElement | React.MutableRefObject<HTMLElement | null>,
            ) => void,
        ) =>
            (e: React.KeyboardEvent<HTMLLIElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                    close()
                    router.push(item.href)
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault()
                    const prevIndex = (index - 1 + company.length) % company.length
                    const prevListItem = document.querySelectorAll('.list-item')[
                        prevIndex
                    ] as HTMLElement
                    prevListItem && prevListItem.focus()
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault()
                    const nextIndex = (index + 1) % company.length
                    const nextListItem = document.querySelectorAll('.list-item')[
                        nextIndex
                    ] as HTMLElement
                    nextListItem?.focus()
                }
            },
        [company.length, router],
    )

    return (
        <Popover.Group className="lg:gap-x-8">
            <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-base font-medium text-gray-900 hover:backdrop-blur-3xl hover:bg-slate-300/25 px-3 h-9 rounded-md">
                    {t('topLevel.company')}
                    <ChevronDownIcon
                        className="size-4 flex-none text-slate-900/35"
                        aria-hidden="true"
                    />
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute top-full z-10 mt-1 w-screen max-w-[14rem] overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg">
                        {({close}) => (
                            <ul className="flex flex-col p-2 gap-2">
                                {company.map((item, index) => (
                                    <li
                                        key={item.name}
                                        className="list-item group rounded-lg leading-6 hover:backdrop-blur-3xl hover:bg-slate-200/55"
                                        tabIndex={0}
                                        onClick={createCloseHandler(close, router, item.href)}
                                        onKeyDown={handleKeyDown(item, index, close)}
                                    >
                                        <Link
                                            href={item.href}
                                            className="relative flex gap-x-2 p-2.5"
                                        >
                                            <div className="flex size-4 flex-none items-start">
                                                <item.icon
                                                    className="size-6 text-gray-400 group-hover:text-gray-900"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="flex-auto">
                                                <span className="block text-base font-semibold text-gray-900">
                                                    {t(`${item.name}.title`)}
                                                </span>
                                                <span className="text-gray-500 text-sm group-hover:text-gray-900">
                                                    {t(`${item.name}.description`)}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </Popover.Group>
    )
}
