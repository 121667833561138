'use client'
import {Bars3BottomRightIcon, XMarkIcon} from '@heroicons/react/20/solid'
// import {Link} from 'navigation'
import Link from 'next/link'
import {useTranslations} from 'next-intl'
import {useCallback, useState} from 'react'

import Logo from '../icons/logo'
import LayoutContent from '../layout/LayoutContent'
import LocaleSwitcher from '../LocaleSwitcher'
import InternalButton from '../ui/internalbutton'
import RenderMobileNav from './renderMobileNav'
import RenderNonMobileNav from './renderNonMobileNav'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const t = useTranslations('Nav')
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        setMobileMenuOpen((open) => !open)
    }, [])

    const closeMenu = useCallback(() => {
        setMobileMenuOpen(false)
    }, [])

    return (
        <header className="fixed backdrop-blur-lg bg-white/30 top-0 left-0 w-full border-b border-b-slate-200/25 h-[var(--navigation-height)] z-[var(--layer-navigation)]">
            <LayoutContent>
                <nav
                    className="block relative w-full overflow-x-auto lg:overflow-visible"
                    role="navigation"
                    aria-label="Main"
                    data-orientation="horizontal"
                >
                    <ul className="relative flex items-center justify-between gap-6">
                        <li className="flex lg:flex-1">
                            <Link
                                href="/"
                                className="flex items-center h-[var(--navigation-height)]"
                                role="link"
                                aria-label="Navigate home"
                                rel="noopener"
                                onClick={closeMenu}
                            >
                                <Logo className="size-8 mr-2" />
                                <span className="text-lg font-semibold text-slate-900/85">
                                    {t('topLevel.flux')}
                                </span>
                            </Link>
                        </li>
                        <li
                            className={classNames(
                                'flex h-full lg:hidden transition-[visibility]',
                                mobileMenuOpen ? 'visible' : 'invisible delay-500',
                            )}
                        >
                            <RenderMobileNav
                                mobileMenuOpen={mobileMenuOpen}
                                onCloseMenu={toggleMenu}
                            />
                        </li>
                        <li className="hidden lg:block">
                            <RenderNonMobileNav />
                        </li>
                        <li className="flex-1">
                            <ul className="flex flex-row space-x-2 justify-end items-center">
                                {/* <li>
                                    <ExternalButton
                                        href="/"
                                        variant="navigation"
                                        size="medium"
                                        shape="rounded"
                                    >
                                        {t('topLevel.login')}
                                    </ExternalButton>
                                </li> */}
                                <li>
                                    <LocaleSwitcher />
                                </li>
                                <li>
                                    <InternalButton
                                        href="/contact"
                                        variant="vibrant"
                                        size="medium"
                                        shape="round"
                                    >
                                        {t('topLevel.signUp')}
                                    </InternalButton>
                                </li>
                                <li className="lg:hidden">
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-gray-50 hover:text-gray-900 transition-colors duration-300"
                                        onClick={toggleMenu}
                                    >
                                        <span className="sr-only">
                                            {mobileMenuOpen
                                                ? t('topLevel.mobilemenuclose')
                                                : t('topLevel.mobilemenuopen')}
                                        </span>
                                        {mobileMenuOpen ? (
                                            <XMarkIcon
                                                className="size-5 transition-transform duration-300 transform rotate-0"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3BottomRightIcon
                                                className="size-5 transition-transform duration-300 transform rotate-0"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </LayoutContent>
        </header>
    )
}
