import {ChatBubbleLeftRightIcon, SparklesIcon} from '@heroicons/react/20/solid'

import {navLinks} from './NavLinks'

export default function getNavCompany() {
    return {
        company: [
            {name: 'about', href: navLinks.about, icon: SparklesIcon},
            // {
            //     name: 'blog',
            //     href: navLinks.blog,
            //     icon: SignalIcon,
            // },
            {
                name: 'contact',
                href: navLinks.contact,
                icon: ChatBubbleLeftRightIcon,
            },
        ],
    } as const
}
