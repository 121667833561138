import classNames from 'classnames'
import {useTranslations} from 'next-intl'
import {useEffect} from 'react'

import {Link} from '@/navigation'

import getNavMobile from './NavMobile'
import getNavSocial from './NavSocialMedia'

export default function RenderMobileNav({mobileMenuOpen, onCloseMenu}: any) {
    const t = useTranslations('Nav')
    const {mobile} = getNavMobile()
    const {social} = getNavSocial()

    useEffect(() => {
        if (mobileMenuOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
        return () => {
            document.body.style.overflow = ''
        }
    }, [mobileMenuOpen])

    return (
        <ul
            className={classNames(
                'mobile-nav flex flex-col fixed left-0 w-full bg-white top-[var(--navigation-height)] h-[calc(100vh_-_var(--navigation-height))] overflow-auto p-2 lg:opacity-100 transition-opacity duration-300 ease-in-out',
                mobileMenuOpen ? 'opacity-100' : 'opacity-0',
            )}
        >
            {mobile.map((item) => (
                <li
                    key={item.name}
                    className={classNames(
                        'mb-2 transition-transform duration-300 ease-in',
                        mobileMenuOpen ? 'translate-y-0' : 'translate-y-6',
                    )}
                >
                    <Link
                        href={item.href}
                        role="link"
                        aria-label={item.name}
                        className="relative flex text-xl font-semibold text-gray-900 hover:text-gray-800/50 p-2"
                        onClick={onCloseMenu}
                    >
                        {t(`${item.name}.title`)}
                    </Link>
                </li>
            ))}
            <ul className="flex overflow-x-auto gap-2 min-h-content mt-auto">
                {social.map((item) => (
                    <li key={item.name} className="group flex justify-center items-center">
                        <a
                            href={item.href}
                            aria-label={item.name}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className="flex items-center justify-center p-4 rounded-md group-hover:bg-slate-200/50"
                            onClick={onCloseMenu}
                        >
                            <svg className="size-6 text-slate-800/85 group-hover:text-slate-800">
                                {item.icon}
                            </svg>
                        </a>
                    </li>
                ))}
            </ul>
        </ul>
    )
}
