import {Popover, PopoverButton, PopoverGroup, PopoverPanel, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {Link, useRouter} from 'navigation'
import {useTranslations} from 'next-intl'
import {Fragment, useCallback} from 'react'

import createCloseHandler from './NavClose'
import getNavProducts from './NavProducts'

export default function NavProductMenu() {
    const t = useTranslations('Nav')
    const router = useRouter()
    const {products} = getNavProducts()

    const handleKeyDownProducts = useCallback(
        (
            item: (typeof products)[number],
            index: number,
            close: (
                focusableElement?: HTMLElement | React.MutableRefObject<HTMLElement | null>,
            ) => void,
        ) =>
            (e: React.KeyboardEvent<HTMLLIElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                    close()
                    router.push(item.href)
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault()
                    const prevIndex = index === 0 ? products.length - 1 : index - 1
                    const prevListItem = document.querySelectorAll('.list-item')[
                        prevIndex
                    ] as HTMLElement
                    prevListItem && prevListItem.focus()
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault()
                    const nextIndex = index === products.length - 1 ? 0 : index + 1
                    const nextListItem = document.querySelectorAll('.list-item')[
                        nextIndex
                    ] as HTMLElement
                    nextListItem && nextListItem.focus()
                } else if (e.key === 'ArrowRight') {
                    e.preventDefault()
                    const subMenuListItem = document.querySelector(
                        '.list-item-cat',
                    ) as HTMLElement
                    subMenuListItem && subMenuListItem.focus()
                }
            },
        [products.length, router],
    )

    return (
        <PopoverGroup className="gap-x-8">
            <Popover className="relative">
                <PopoverButton className="flex items-center gap-x-1 text-base font-medium text-gray-900 hover:backdrop-blur-3xl hover:bg-slate-300/25 px-3 h-9 rounded-md">
                    {t('topLevel.product')}
                    <ChevronDownIcon
                        className="size-4 flex-none text-slate-900/35"
                        aria-hidden="true"
                    />
                </PopoverButton>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute top-full z-50 mt-1 w-max max-w-xl overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg">
                        {({close}) => (
                            <ul className="flex flex-row p-2 gap-2">
                                <li className="flex-none">
                                    <ul className="flex flex-col">
                                        {products.map((item, index) => (
                                            <li
                                                key={item.name}
                                                className="list-item group rounded-lg leading-6 hover:backdrop-blur-3xl hover:bg-slate-200/55"
                                                tabIndex={0}
                                                onClick={createCloseHandler(
                                                    close,
                                                    router,
                                                    item.href,
                                                )}
                                                onKeyDown={handleKeyDownProducts(
                                                    item,
                                                    index,
                                                    close,
                                                )}
                                            >
                                                <Link
                                                    href={item.href}
                                                    className="relative flex gap-x-2 p-2.5"
                                                >
                                                    <div className="flex size-4 flex-none items-start">
                                                        <item.icon
                                                            className="size-6 text-gray-400 group-hover:text-gray-900"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="flex-auto">
                                                        <span className="block text-base font-semibold text-gray-900">
                                                            {t(`${item.name}.title`)}
                                                        </span>
                                                        <span className="text-gray-500 text-sm group-hover:text-gray-900">
                                                            {t(`${item.name}.description`)}
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                {/* <li className="flex-1">
                                    <ul className="flex flex-col gap-2 h-full">
                                        {callsToAction.map((item, index) => (
                                            <li
                                                key={item.name}
                                                tabIndex={0}
                                                className="list-item-cat group relative flex-1 rounded-lg p-2 text-base leading-6 hover:backdrop-blur-3xl hover:bg-slate-200/55 border border-slate-300 bg-slate-50"
                                                onClick={createCloseHandler(
                                                    close,
                                                    router,
                                                    item.href,
                                                )}
                                                onKeyDown={handleKeyDownCaO(item, index, close)}
                                            >
                                                <Link href={item.href} className="text-base">
                                                    <div className="flex flex-col grow h-full">
                                                        <span className="font-semibold text-gray-900">
                                                            {t(`${item.name}.title`)}
                                                        </span>
                                                        <div className="flex flex-col justify-end h-full">
                                                            <p className="text-sm text-gray-500 group-hover:text-gray-900">
                                                                {t(`${item.name}.description`)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li> */}
                            </ul>
                        )}
                    </PopoverPanel>
                </Transition>
            </Popover>
        </PopoverGroup>
    )
}
