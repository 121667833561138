import {VariantProps} from 'class-variance-authority'
import classNames from 'classnames'

import {layoutClasses} from './LayoutClasses'

interface LayoutProps extends VariantProps<typeof layoutClasses> {
    children: React.ReactNode
    className?: string
    id?: string
}

export default function LayoutContent({children, variant, className, id}: LayoutProps) {
    const layoutClass = classNames(layoutClasses({variant}), className)
    return (
        <div className={layoutClass} id={id}>
            {children}
        </div>
    )
}
