import {navLinks} from './NavLinks'

export default function getNavMobile() {
    return {
        mobile: [
            {
                name: 'product',
                href: '/',
            },
            // {
            //     name: 'analytics',
            //     href: navLinks.analytics,
            // },
            {
                name: 'integrations',
                href: navLinks.integrations,
            },
            // {
            //     name: 'security',
            //     href: navLinks.security,
            // },
            // {
            //     name: 'pricing',
            //     href: navLinks.pricing,
            // },
            {
                name: 'customers',
                href: navLinks.customers,
            },
            {
                name: 'changelog',
                href: navLinks.changelog,
            },
            {
                name: 'about',
                href: navLinks.about,
            },
            // {
            //     name: 'blog',
            //     href: navLinks.blog,
            // },
            {
                name: 'contact',
                href: navLinks.contact,
            },
        ],
    } as const
}
